.bg-darker-1 {
  background-color: rgba(0, 0, 0, 0.05); }

.bg-darker-2 {
  background-color: rgba(0, 0, 0, 0.1); }

.bg-darker-3 {
  background-color: rgba(0, 0, 0, 0.15); }

.bg-darker-4 {
  background-color: rgba(0, 0, 0, 0.2); }

.bg-darker-5 {
  background-color: rgba(0, 0, 0, 0.25); }

.bg-lighter-1 {
  background-color: rgba(255, 255, 255, 0.05); }

.bg-lighter-2 {
  background-color: rgba(255, 255, 255, 0.1); }

.bg-lighter-3 {
  background-color: rgba(255, 255, 255, 0.15); }

.bg-lighter-4 {
  background-color: rgba(255, 255, 255, 0.2); }

.bg-lighter-5 {
  background-color: rgba(255, 255, 255, 0.35); }

.inline-block {
  display: inline-block; }

div.ReactTags__tags {
  position: relative; }

/* Styles for the input */
div.ReactTags__tagInput {
  display: inline-block; }

div.ReactTags__tagInput input.ReactTags__tagInputField,
div.ReactTags__tagInput input.ReactTags__tagInputField:focus {
  padding: 5px;
  border-radius: 0.25rem;
  border: solid 1px rgba(255, 255, 255, 0.15); }

/* Styles for selected tags */
div.ReactTags__selected span.ReactTags__tag {
  display: inline-block;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 5px;
  margin-right: 5px;
  border-radius: 0.25rem;
  border: solid 1px rgba(255, 255, 255, 0.15); }
  div.ReactTags__selected span.ReactTags__tag:hover {
    background-color: rgba(255, 255, 255, 0.2); }

div.ReactTags__selected a.ReactTags__remove {
  color: #aaa;
  margin-left: 5px;
  text-align: center;
  padding: 0 5px;
  cursor: pointer;
  float: right;
  font-weight: bold; }

/* Styles for suggestions */
div.ReactTags__suggestions {
  position: absolute; }

div.ReactTags__suggestions ul {
  list-style-type: none;
  box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
  background: white;
  width: 200px; }

div.ReactTags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 5px 10px;
  margin: 0; }

div.ReactTags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600; }

div.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
  background: #b7cfe0;
  cursor: pointer; }

.tag .tag-contents {
  display: flex;
  flex-direction: row;
  align-items: center; }
  .tag .tag-contents .tag-color-box {
    width: 15px;
    height: 15px;
    margin-right: 5px;
    margin-left: 2px;
    border-radius: 0.25rem; }
